// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Controls_controls__w3u4- {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  gap: var(--fxl-spacing-xxs);\n}\n\n.Controls_blade__D94Fn {\n  display: flex;\n  gap: var(--fxl-spacing-xxs);\n  align-items: center;\n}\n.Controls_blade__D94Fn > div {\n  flex-grow: 1;\n}", "",{"version":3,"sources":["webpack://./src/components/FxParams/Controls.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,2BAAA;AACF;;AACA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAEF;AADE;EACE,YAAA;AAGJ","sourcesContent":[".controls {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  gap: var(--fxl-spacing-xxs);\n}\n.blade {\n  display: flex;\n  gap: var(--fxl-spacing-xxs);\n  align-items: center;\n  & > div {\n    flex-grow: 1;\n  }\n  .lockButton {\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "Controls_controls__w3u4-",
	"blade": "Controls_blade__D94Fn"
};
export default ___CSS_LOADER_EXPORT___;
