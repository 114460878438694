// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelHeader_root__flIIg h1 {\n  display: block;\n}\n.PanelHeader_root__flIIg small {\n  font-size: var(--fxl-font-size-sm);\n  color: var(--fxl-color-text-secondary);\n}", "",{"version":3,"sources":["webpack://./src/containers/Panel/PanelHeader.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,kCAAA;EACA,sCAAA;AAAJ","sourcesContent":[".root {\n  h1 {\n    display: block;\n  }\n  small {\n    font-size: var(--fxl-font-size-sm);\n    color: var(--fxl-color-text-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PanelHeader_root__flIIg"
};
export default ___CSS_LOADER_EXPORT___;
