// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelControls_controlPanel__N1n-4 {\n  display: flex;\n  flex-direction: column;\n  padding: var(--fxl-spacing);\n  gap: var(--fxl-spacing);\n}\n\n.PanelControls_checkboxWrapper__8q3-3 {\n  display: flex;\n  align-items: center;\n}\n.PanelControls_checkboxWrapper__8q3-3 label {\n  margin-left: var(--fxl-spacing-sm);\n}\n\n.PanelControls_buttonsWrapper__eJQT5 {\n  display: flex;\n  gap: var(--fxl-spacing);\n}\n.PanelControls_buttonsWrapper__eJQT5 > button {\n  flex-grow: 1;\n}\n\n.PanelControls_updateCheckbox__wYpBW {\n  display: none;\n}\n.PanelControls_updateCheckbox__wYpBW:checked ~ label::before {\n  background: var(--fxl-color-light1);\n  box-shadow: 3px 3px 0px var(--fxl-color-dark2) inset, -3px -3px 0px var(--fxl-color-dark2) inset;\n}", "",{"version":3,"sources":["webpack://./src/containers/Panel/PanelControls.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,uBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;AADE;EACE,kCAAA;AAGJ;;AAAA;EACE,aAAA;EACA,uBAAA;AAGF;AAFE;EACE,YAAA;AAIJ;;AAAA;EACE,aAAA;AAGF;AADI;EACE,mCAAA;EACA,gGAAA;AAGN","sourcesContent":[".controlPanel {\n  display: flex;\n  flex-direction: column;\n  padding: var(--fxl-spacing);\n  gap: var(--fxl-spacing);\n}\n.checkboxWrapper {\n  display: flex;\n  align-items: center;\n  label {\n    margin-left: var(--fxl-spacing-sm)\n  }\n}\n.buttonsWrapper {\n  display: flex;\n  gap: var(--fxl-spacing);\n  & > button {\n    flex-grow: 1;\n  }\n}\n\n.updateCheckbox {\n  display: none;\n  &:checked ~ label {\n    &::before {\n      background: var(--fxl-color-light1);\n      box-shadow: 3px 3px 0px var(--fxl-color-dark2) inset, -3px -3px 0px var(--fxl-color-dark2) inset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlPanel": "PanelControls_controlPanel__N1n-4",
	"checkboxWrapper": "PanelControls_checkboxWrapper__8q3-3",
	"buttonsWrapper": "PanelControls_buttonsWrapper__eJQT5",
	"updateCheckbox": "PanelControls_updateCheckbox__wYpBW"
};
export default ___CSS_LOADER_EXPORT___;
