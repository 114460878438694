// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_root__HUqHd {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n}\n.Layout_root__HUqHd .Layout_panel__cb0ET {\n  width: 400px;\n  height: 100%;\n  background-color: var(--fxl-color-dark);\n}\n.Layout_root__HUqHd .Layout_frame__fHpZI {\n  flex-grow: 2;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/containers/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;EACA,uCAAA;AACJ;AAEE;EACE,YAAA;EACA,YAAA;AAAJ","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n\n  .panel {\n    width: 400px;\n    height: 100%;\n    background-color: var(--fxl-color-dark);\n  }\n\n  .frame {\n    flex-grow: 2;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Layout_root__HUqHd",
	"panel": "Layout_panel__cb0ET",
	"frame": "Layout_frame__fHpZI"
};
export default ___CSS_LOADER_EXPORT___;
