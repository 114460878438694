// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelRoot_root__Tr5CK {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  font-size: var(--fxl-font-size-s);\n}\n.PanelRoot_root__Tr5CK .PanelRoot_scrollWrapper__2AaLF {\n  flex-grow: 1;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  padding: var(--fxl-spacing) var(--fxl-spacing) 0 var(--fxl-spacing);\n}\n.PanelRoot_root__Tr5CK .PanelRoot_body__ZjTaN {\n  flex-grow: 1;\n}", "",{"version":3,"sources":["webpack://./src/containers/Panel/PanelRoot.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,iCAAA;AACF;AAAE;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,mEAAA;AAEJ;AAAE;EACE,YAAA;AAEJ","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  font-size: var(--fxl-font-size-s);\n  .scrollWrapper {\n    flex-grow: 1;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    padding: var(--fxl-spacing) var(--fxl-spacing) 0 var(--fxl-spacing);\n  }\n  .body {\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PanelRoot_root__Tr5CK",
	"scrollWrapper": "PanelRoot_scrollWrapper__2AaLF",
	"body": "PanelRoot_body__ZjTaN"
};
export default ___CSS_LOADER_EXPORT___;
