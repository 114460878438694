// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Frame_root__T66I\\+ {\n  width: 100%;\n  height: 100%;\n  border: none;\n}", "",{"version":3,"sources":["webpack://./src/components/Frame/Frame.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Frame_root__T66I+"
};
export default ___CSS_LOADER_EXPORT___;
