// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_root__KWTBx {\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/containers/App.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,gBAAA;AACF","sourcesContent":[".root {\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "App_root__KWTBx"
};
export default ___CSS_LOADER_EXPORT___;
