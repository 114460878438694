// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Features_featureList__G-qQK {\n  list-style-type: none;\n  padding: 0;\n}\n.Features_featureList__G-qQK li {\n  font-size: var(--fxl-font-size-s);\n  display: flex;\n  margin-bottom: var(--fxl-spacing-sm);\n  align-items: center;\n}\n.Features_featureList__G-qQK li :first-child {\n  flex-grow: 1;\n}\n.Features_featureList__G-qQK li :last-child {\n  margin-left: 10px;\n  width: 20px;\n  min-width: 200px;\n}", "",{"version":3,"sources":["webpack://./src/components/Features/Features.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,UAAA;AACF;AACE;EACE,iCAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;AACJ;AACI;EACE,YAAA;AACN;AACI;EACE,iBAAA;EACA,WAAA;EACA,gBAAA;AACN","sourcesContent":[".featureList {\n  list-style-type: none;\n  padding: 0;\n\n  li {\n    font-size: var(--fxl-font-size-s);\n    display: flex;\n    margin-bottom: var(--fxl-spacing-sm);\n    align-items: center;\n\n    & :first-child {\n      flex-grow: 1;\n    }\n    & :last-child {\n      margin-left: 10px;\n      width: 20px;\n      min-width: 200px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featureList": "Features_featureList__G-qQK"
};
export default ___CSS_LOADER_EXPORT___;
