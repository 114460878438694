// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelGroup_root__ikwRp .PanelGroup_description__RqxC7 {\n  color: var(--fxl-color-text-secondary);\n  font-size: var(--fxl-font-size-s);\n}", "",{"version":3,"sources":["webpack://./src/components/Panel/PanelGroup.module.scss"],"names":[],"mappings":"AAKE;EACE,sCAAA;EACA,iCAAA;AAJJ","sourcesContent":[".root {\n  .title {\n\n  }\n\n  .description {\n    color: var(--fxl-color-text-secondary);\n    font-size: var(--fxl-font-size-s);\n  }\n\n  .content {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PanelGroup_root__ikwRp",
	"description": "PanelGroup_description__RqxC7"
};
export default ___CSS_LOADER_EXPORT___;
