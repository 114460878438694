// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelAddress_hashControls__-q4Pu {\n  display: flex;\n}\n.PanelAddress_hashControls__-q4Pu button img {\n  color: var(--fxl-color-text-primary);\n  height: 100%;\n  width: 100%;\n}\n\n.PanelAddress_hashInput__2r8Rq {\n  flex-grow: 1;\n  margin-right: var(--fxl-spacing-xs);\n  font-size: var(--fxl-font-size-xs);\n}", "",{"version":3,"sources":["webpack://./src/containers/Panel/PanelAddress.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,oCAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAGA;EACE,YAAA;EACA,mCAAA;EACA,kCAAA;AAAF","sourcesContent":[".hashControls {\n  display: flex;\n\n  button img {\n    color: var(--fxl-color-text-primary);\n    height: 100%;\n    width: 100%;\n  }\n}\n\n.hashInput {\n  flex-grow: 1;\n  margin-right: var(--fxl-spacing-xs);\n  font-size: var(--fxl-font-size-xs);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hashControls": "PanelAddress_hashControls__-q4Pu",
	"hashInput": "PanelAddress_hashInput__2r8Rq"
};
export default ___CSS_LOADER_EXPORT___;
