// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Progress_progress__Fnp1v {\n  position: relative;\n  width: 100%;\n  margin-bottom: var(--fxl-spacing);\n  display: flex;\n  flex-direction: column;\n  gap: var(--fxl-spacing-xxs);\n}\n.Progress_progress__Fnp1v span {\n  color: var(--fxl-color-text-primary);\n  font-size: var(--fxl-font-size-s);\n  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;\n}\n\n.Progress_barWrapper__o\\+AYE {\n  border: 1px solid var(--fxl-color-light2);\n  width: 100%;\n  padding: 2px;\n}\n\n.Progress_bar__8PEWU {\n  height: 10px;\n  background: var(--fxl-color-light2);\n}", "",{"version":3,"sources":["webpack://./src/components/ProgressBar/Progress.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,WAAA;EACA,iCAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACD;AAAC;EACG,oCAAA;EACF,iCAAA;EAEA,+EAAA;AACF;;AAKA;EACC,yCAAA;EACA,WAAA;EACA,YAJe;AAEhB;;AAKA;EACC,YAAA;EACA,mCAAA;AAFD","sourcesContent":[".progress {\n\tposition: relative;\n\twidth: 100%;\n\tmargin-bottom: var(--fxl-spacing);\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: var(--fxl-spacing-xxs);\n\tspan {\n    color: var(--fxl-color-text-primary);\n\t\tfont-size: var(--fxl-font-size-s);\n//\t\talign-self: flex-end;\n\t\ttext-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;\n\t}\n}\n\n$border-width: 1px;\n$border-offset: 2px;\n.barWrapper{\n\tborder: $border-width solid var(--fxl-color-light2);\n\twidth: 100%;\n\tpadding: $border-offset;\n}\n\n.bar {\n\theight: 10px;\n\tbackground: var(--fxl-color-light2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "Progress_progress__Fnp1v",
	"barWrapper": "Progress_barWrapper__o+AYE",
	"bar": "Progress_bar__8PEWU"
};
export default ___CSS_LOADER_EXPORT___;
