// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LockButton_lockButton__9T\\+Yt {\n  color: var(--fxl-color-light1) !important;\n}", "",{"version":3,"sources":["webpack://./src/components/FxParams/LockButton/LockButton.module.scss"],"names":[],"mappings":"AACA;EACE,yCAAA;AAAF","sourcesContent":["\n.lockButton {\n  color: var(--fxl-color-light1) !important; \n  &.isOpen {\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lockButton": "LockButton_lockButton__9T+Yt"
};
export default ___CSS_LOADER_EXPORT___;
