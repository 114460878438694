// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelIteration_iterationControls__Gw45v {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n}\n\n.PanelIteration_numberInput__zt-fb {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/containers/Panel/PanelIteration.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".iterationControls {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n}\n\n.numberInput {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iterationControls": "PanelIteration_iterationControls__Gw45v",
	"numberInput": "PanelIteration_numberInput__zt-fb"
};
export default ___CSS_LOADER_EXPORT___;
